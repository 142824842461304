import { createContext, useContext, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import Pusher from "pusher-js";

import CircularProgressWithLabel from "shared/components/CircularProgressWithLabel";
import CloseIconButton from "shared/components/buttons/CloseIconButton";
import { uploadStatus } from "shared/generated/clients/fleet.client";
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "shared/lib/localStorage";

import { useFleets } from "./FleetProvider";

type UploadStatusUIProps = {
  onClose: () => void;
  progressPercent: number;
  successCount: number;
  failedCount: number;
  totalCount: number;
  uploadErrors: { error: string; vehicle: UploadedVehicleInfo }[];
};

function UploadStatus({
  onClose,
  progressPercent,
  successCount,
  failedCount,
  totalCount,
  uploadErrors,
}: Readonly<UploadStatusUIProps>) {
  const [showDetails, setShowDetails] = useState(false);
  const theme = useTheme();

  return (
    <Card
      sx={{
        minWidth: 400,
        maxWidth: { xs: "100%", sm: theme.breakpoints.values.sm },
        width: "100%",
        position: "fixed",
        right: 0,
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <CardHeader
        title="Vehicle Upload"
        action={<CloseIconButton handleClose={onClose} />}
      />
      <CardContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color="success">
            Uploaded {successCount + failedCount} of {totalCount}
          </Typography>
          <CircularProgressWithLabel value={progressPercent} />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color="error">Failed {failedCount}</Typography>
          {!!failedCount && (
            <Button size="small" onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? "Hide Details" : "Show Details"}
            </Button>
          )}
        </Box>
        {showDetails && (
          <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>VIN</TableCell>
                  <TableCell>License Plate</TableCell>
                  <TableCell>Error Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadErrors.map((err) => (
                  <TableRow key={`failed-job-status-${err.vehicle.vin}`}>
                    <TableCell>{err.vehicle.nickname}</TableCell>
                    <TableCell>{err.vehicle.vin}</TableCell>
                    <TableCell>{err.vehicle.plate}</TableCell>
                    <TableCell>{err.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

type UploadedVehicleInfo = {
  id: number;
  nickname: string;
  vin?: string;
  plate?: string;
  state?: string;
};

interface VehicleUploadValues {
  setUploadJobId: (jobId: number) => void;
  progressPercent: number;
}

// Create the Context
const VehicleUploadStatusContext = createContext<
  VehicleUploadValues | undefined
>(undefined);

const UPLOAD_JOB_ID_STORAGE_KEY = "uploadJobId";

type UploadResult = NonNullable<
  Required<Awaited<ReturnType<typeof uploadStatus>>>["data"]["returnValue"]
>[0];

function isUploadResult(data: unknown): data is UploadResult {
  return typeof data === "object" && !!data && "vehicle" in data;
}

function isUploadResultWithError(
  data: unknown
): data is UploadStatusUIProps["uploadErrors"][0] {
  return (
    isUploadResult(data) && "error" in data && typeof data.error === "string"
  );
}

export const VehicleUploadStatusProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const [uploadJobId, setUploadJobId] = useState<number | null>(
    loadFromLocalStorage(UPLOAD_JOB_ID_STORAGE_KEY)
  );
  const [showInfo, setShowInfo] = useState(false);
  const { currentFleetIdAsNumber, isValidFleetId } = useFleets();
  const [vehiclesToImport, setVehiclesToImport] = useState<unknown[]>([]);
  const [results, setResults] = useState<UploadResult[]>([]);

  const progressPercent = useMemo(() => {
    if (vehiclesToImport.length === 0) return 0;
    return (results.length / vehiclesToImport.length) * 100;
  }, [vehiclesToImport.length, results.length]);

  useEffect(() => {
    async function load() {
      if (!isValidFleetId || !uploadJobId) return;
      const response = await uploadStatus({
        params: {
          path: { fleetId: currentFleetIdAsNumber, jobId: uploadJobId },
        },
      });
      if (response.error) {
        console.error("Error loading upload job status", response.error);
        return;
      }

      setVehiclesToImport(response.data.data?.vehicles ?? []);
      setResults(response.data.returnValue ?? []);
    }

    load();
  }, [currentFleetIdAsNumber, uploadJobId]);

  useEffect(() => {
    const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_API_KEY, {
      cluster: "us2",
    });
    const channelName = `vehicle-upload-${uploadJobId}`;
    const channel = pusher.subscribe(channelName);

    channel.bind("success", async function (data: unknown) {
      console.debug(`New pusher message ${channelName}@success`, data);
      if (!isUploadResult(data)) return;
      setResults((prev) => [...prev, data]);
    });
    channel.bind("error", async function (data: unknown) {
      console.debug(`New pusher message ${channelName}@error`, data);
      if (!isUploadResult(data)) return;
      setResults((prev) => [...prev, data]);
    });

    // Cleanup: Unsubscribe and unbind the channel when the component unmounts
    return () => {
      channel.unbind("success");
      channel.unbind("error");
      pusher.unsubscribe(channelName);
    };
  }, [uploadJobId]);

  useEffect(() => {
    if (progressPercent < 100 || !isValidFleetId || !uploadJobId) return;

    setShowInfo(true);
  }, [isValidFleetId, progressPercent, router, showInfo, uploadJobId]);

  const value = useMemo(() => {
    return {
      setUploadJobId: (jobId: number) => {
        saveToLocalStorage(UPLOAD_JOB_ID_STORAGE_KEY, jobId);
        setUploadJobId(jobId);
      },
      progressPercent,
      totalCount: vehiclesToImport.length,
      successCount: results.filter((r) => !r.error).length,
      failedCount: results.filter((r) => r.error).length,
    };
  }, [progressPercent, vehiclesToImport.length, results]);

  return (
    <VehicleUploadStatusContext.Provider value={value}>
      {currentFleetIdAsNumber && uploadJobId && (
        <UploadStatus
          {...value}
          uploadErrors={results.flatMap((r) =>
            isUploadResultWithError(r) ? [r] : []
          )}
          onClose={() => {
            setUploadJobId(null);
            if (progressPercent === 100) {
              removeFromLocalStorage(UPLOAD_JOB_ID_STORAGE_KEY);
              router.reload();
            }
          }}
        />
      )}
      {children}
    </VehicleUploadStatusContext.Provider>
  );
};

export const useVehicleUploadContext = () => {
  const context = useContext(VehicleUploadStatusContext);
  if (context === undefined) {
    throw new Error(
      "useVehicleUploadContext must be used within a VehicleUploadStatusProvider"
    );
  }
  return context;
};
