import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import {
  GetMyFleetsQuery,
  GetMyFleetsQueryResult,
  useGetMyFleetsQuery,
} from "shared/generated/graphql";
import {
  loadFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "shared/lib/localStorage";
import { useAuth } from "shared/providers/AuthProvider";

export type FleetIdQueryParam = number | null | "all";

type FleetContextProps = {
  currentFleetId: FleetIdQueryParam;
  currentFleetIdAsNumber: number;
  currentFleetName?: string;
  currentFleetIds?: number[];
  isValidFleetId: boolean;
  loading: boolean;
  refetch: GetMyFleetsQueryResult["refetch"];
  fleets?: GetMyFleetsQuery["fleets"];
  currentFleets?: GetMyFleetsQuery["fleets"];
  isFleetUser: boolean;
  resetCurrentFleet: () => Promise<void>;
  setCurrentFleetId: (fleetId: number | "all") => Promise<void>;
};

const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

const FleetContext = createContext<FleetContextProps | null>(null);

export function useFleets() {
  const context = useContext(FleetContext);
  if (!context) {
    throw new Error("useFleets must be used within a FleetProvider");
  }
  return context;
}

export const FleetProvider = ({ children }: { children: ReactNode }) => {
  const { allFleets: allFleetsFlag } = useFlags();
  const router = useRouter();
  const currentPath = usePathname();
  const params = useSearchParams();
  const fleetIdParams = params.get("fleetId");
  const currentFleetId =
    allFleetsFlag && fleetIdParams === "all"
      ? "all"
      : Number(fleetIdParams) || null;

  const { currentUser } = useAuth();
  const { data, loading, refetch } = useGetMyFleetsQuery({
    fetchPolicy: "network-only",
    skip: !currentUser,
  });

  const lastFleetStorageKey = currentUser
    ? `defaultFleet-${currentUser.uid}`
    : null;
  const lastFleetId = lastFleetStorageKey
    ? loadFromLocalStorage(lastFleetStorageKey)
    : null;

  useEffect(() => {
    if (!currentFleetId || !lastFleetStorageKey) return;

    saveToLocalStorage(lastFleetStorageKey, currentFleetId);
  }, [currentFleetId, lastFleetStorageKey]);

  const resetCurrentFleet = useCallback(async () => {
    if (!lastFleetStorageKey) return;

    removeFromLocalStorage(lastFleetStorageKey);
    await refetch();
  }, [lastFleetStorageKey, refetch]);

  useEffect(() => {
    if (!lastFleetStorageKey || loading) return;

    if (
      isInsurance &&
      data?.fleets &&
      !data?.fleets.some((fleet) => fleet.id === lastFleetId)
    ) {
      resetCurrentFleet(); // Reset if last fleet ID is invalid
    }
  }, [
    data?.fleets,
    loading,
    resetCurrentFleet,
    lastFleetId,
    lastFleetStorageKey,
  ]);

  useEffect(() => {
    if (loading || !data?.fleets) return;

    const defaultFleet = lastFleetId ?? data?.fleets[0]?.id;
    if (!currentFleetId && defaultFleet) {
      // Add fleet id to URL if not present
      router.replace({
        pathname: router.pathname ?? "/",
        query: {
          fleetId: defaultFleet.toString(),
        },
      });
    }
  }, [data?.fleets, lastFleetId, currentFleetId, router, loading]);

  const setCurrentFleetId = useCallback(
    async (fleetId: number | "all") => {
      const pathSegments = currentPath.split("/").filter(Boolean);

      //If user is in a nested page, redirect to the top-level page
      const redirectPath =
        pathSegments.length > 1 ? `/${pathSegments[0]}` : currentPath;

      await router.push({
        pathname: redirectPath,
        query: {
          fleetId: fleetId.toString(),
        },
      });

      if (lastFleetStorageKey) {
        saveToLocalStorage(lastFleetStorageKey, fleetId);
      }
    },
    [currentPath, lastFleetStorageKey, router]
  );

  const allFleets = data?.fleets || [];
  const currentFleets =
    currentFleetId === "all"
      ? allFleets
      : allFleets.filter((fleet) => fleet.id === currentFleetId);
  const currentFleetIdAsNumber = Number(currentFleetId) || -1;

  const contextValue: FleetContextProps = useMemo(
    () => ({
      currentFleetId,
      currentFleetIdAsNumber,
      currentFleetName: currentFleets?.[0]?.name,
      currentFleetIds: currentFleets?.map((fleet) => fleet.id),
      isValidFleetId: currentFleetIdAsNumber !== -1,
      loading,
      refetch,
      fleets: data?.fleets,
      currentFleets,
      resetCurrentFleet,
      setCurrentFleetId,
      isFleetUser: Boolean(data?.fleets?.length),
    }),
    [
      currentFleetId,
      currentFleetIdAsNumber,
      currentFleets,
      resetCurrentFleet,
      setCurrentFleetId,
      data?.fleets,
      loading,
      refetch,
    ]
  );

  return (
    <FleetContext.Provider value={contextValue}>
      {children}
    </FleetContext.Provider>
  );
};
