import { createContext, useState } from "react";

import { Box, Button, Container, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/nextjs";

interface ErrorContextProps {
  errorMessage: string;
}

const ErrorContext = createContext<ErrorContextProps>({
  errorMessage: "",
});

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState("");
  if (typeof window !== "undefined") {
    window.onunhandledrejection = (event) => {
      console.error(
        `Unhandled rejection reason: ${event.reason}`,
        typeof event.reason,
        event.reason
      );
      Sentry.captureException(event.reason.toString());
      const message = event.reason.toString();
      if (message.toLowerCase().includes("magicbell")) {
        return;
      }

      setErrorMessage(message);
    };
  }

  return (
    <ErrorContext.Provider value={{ errorMessage }}>
      {errorMessage ? (
        <Container maxWidth={"md"}>
          <Stack mt={20} gap={2} display="flex" justifyContent="center">
            <Typography textAlign={"center"} variant="h3">
              An error occurred
            </Typography>
            <Typography variant="h6" textAlign="center">
              {errorMessage.length ? errorMessage : "Unknown error"}
            </Typography>
            <Box display="flex" justifyContent="center">
              {/* Explicitly not using a nextjs Link component because we want to hard navigate to the root of the app */}
              <Button
                variant="contained"
                size="large"
                href="/"
                sx={{ width: "fit-content" }}
              >
                Back to my fleet
              </Button>
            </Box>
          </Stack>
        </Container>
      ) : (
        children
      )}
    </ErrorContext.Provider>
  );
};
