import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import Image from "next/image";

export default function ImageBox({
  image,
  onDelete,
}: {
  image: File;
  onDelete?: () => void;
}) {
  return (
    <Box
      mt={2}
      display="flex"
      alignItems="center"
      flexDirection="row"
      style={{
        backgroundColor: "#F4F4F4",
        borderRadius: "8px",
        height: "80px",
        width: "100%",
      }}
    >
      <Box ml={2} mt={1}>
        <Image
          src={URL.createObjectURL(image)}
          alt={image.name}
          width={48}
          height={48}
        />
      </Box>
      <Typography ml={2}>{image.name}</Typography>
      <IconButton sx={{ ml: "auto", mr: 1 }} onClick={onDelete}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
