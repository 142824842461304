import { ReactNode } from "react";

import MuiLink from "@mui/material/Link";
import { LinkProps as NextLinkProps } from "next/dist/client/link";
import Link from "next/link";

type StyledLinkProps = NextLinkProps & {
  children: ReactNode;
  LinkProps?: React.ComponentProps<typeof MuiLink>;
};

export default function StyledLink({
  children,
  LinkProps,
  ...nextLinkProps
}: StyledLinkProps) {
  return (
    <Link {...nextLinkProps} passHref>
      <MuiLink component="span" {...LinkProps}>
        {children}
      </MuiLink>
    </Link>
  );
}
