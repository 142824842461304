import { Box, Toolbar } from "@mui/material";

import { FleetProvider } from "../../providers/FleetProvider";
import { ServicesFlowProvider } from "../../providers/ServiceFlowProvider";
import { TagsProvider } from "../../providers/TagsProvider";
import { VehicleUploadStatusProvider } from "../../providers/VehicleUploadStatusProvider";
import Navbar from "../navbar/Navbar";

const Layout: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <FleetProvider>
      <TagsProvider>
        <ServicesFlowProvider>
          <VehicleUploadStatusProvider>
            <Navbar />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                overflow: "auto",
                overscrollBehavior: "none",
              }}
            >
              <Toolbar />
              {children}
            </Box>
          </VehicleUploadStatusProvider>
        </ServicesFlowProvider>
      </TagsProvider>
    </FleetProvider>
  );
};

export default Layout;
