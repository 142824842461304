import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";

export default function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    showCompleteAsCheck?: boolean;
  }
) {
  const showCompleteAsCheck = props.showCompleteAsCheck ?? true;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
      >
        {props.value === 100 && showCompleteAsCheck ? (
          <CheckIcon />
        ) : (
          props.value && (
            <Typography variant="caption" component="div" color="textSecondary">
              {`${Math.round(props.value)}`}
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
}
