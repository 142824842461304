import { ReactNode } from "react";

import { Container } from "@mui/material";

import PageHead from "../PageHead";

export default function LayoutWithoutNavbarFleet({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <>
      <PageHead />
      <Container
        sx={{
          maxWidth: { xs: "100%", sm: "sm" },
        }}
      >
        {children}
      </Container>
    </>
  );
}
