import { ReactNode } from "react";

import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Stack } from "@mui/material";

import GenericModal, { GenericModalProps } from "./GenericModal";

interface FormModalProps
  extends Pick<
    GenericModalProps,
    | "open"
    | "handleClose"
    | "title"
    | "subtitle"
    | "content"
    | "maxWidth"
    | "disableBackdropClick"
    | "secondaryModalAction"
  > {
  icon?: ReactNode;
  onSubmit: () => Promise<void>;
  loading: boolean;
  isValid: boolean;
  globalErrorMessage?: string | null;
  submitButtonText?: string;
  secondaryButton?: JSX.Element;
}

export default function FormModal({
  icon,
  onSubmit,
  handleClose,
  content,
  loading,
  isValid,
  globalErrorMessage,
  submitButtonText = "Save",
  secondaryButton,
  ...rest
}: Readonly<FormModalProps>) {
  const modalActions = (
    <>
      {secondaryButton ?? (
        <Button variant="outlined" size="large" onClick={handleClose}>
          Cancel
        </Button>
      )}

      <LoadingButton
        variant="contained"
        size="large"
        loading={loading}
        disabled={!isValid}
        type="submit"
      >
        {submitButtonText}
      </LoadingButton>
    </>
  );

  const modalContent = (
    <Box>
      {globalErrorMessage && (
        <Box mb={2}>
          <Alert severity="error" data-testid="formmodal-errormsg">
            {globalErrorMessage}
          </Alert>
        </Box>
      )}
      <Stack mx={3}>{content}</Stack>
    </Box>
  );

  return (
    <GenericModal
      headerIcon={icon}
      content={modalContent}
      handleClose={handleClose}
      onSubmit={onSubmit}
      modalActions={modalActions}
      {...rest}
    />
  );
}
