import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

import {
  GetFleetVehiclesTagsQuery,
  GetFleetVehiclesTagsQueryResult,
  useGetFleetVehiclesTagsQuery,
} from "shared/generated/graphql";

import { useFleets } from "./FleetProvider";

type FleetVehiclesTagsType = Omit<
  GetFleetVehiclesTagsQuery["tagsFleetVehicles"][number],
  "id" | "key"
> & {
  id: number;
  key: string;
};

type TagsContextProps = {
  showAddTag: boolean;
  setShowAddTag: Dispatch<SetStateAction<boolean>>;
  fleetTagsList: FleetVehiclesTagsType[];
  refetch: GetFleetVehiclesTagsQueryResult["refetch"];
  findTagsByFleetId: (fleetId: number) => FleetVehiclesTagsType[] | undefined;
};

const TagsContext = createContext<TagsContextProps | null>(null);

export function useTags() {
  const context = useContext(TagsContext);
  if (!context) {
    throw new Error("useTags must be used within TagProvider");
  }
  return context;
}

export function TagsProvider({ children }: { children: ReactNode }) {
  const [showAddTag, setShowAddTag] = useState(false);
  const { currentFleetIds } = useFleets();

  const { data, refetch } = useGetFleetVehiclesTagsQuery({
    skip: !currentFleetIds,
    variables: {
      fleetIds: currentFleetIds,
    },
  });

  const fleetTagsList =
    data?.tagsFleetVehicles.flatMap((tag) => {
      if (
        !tag.id ||
        typeof tag.id !== "number" ||
        !tag.key ||
        typeof tag.key !== "string"
      ) {
        return [];
      }

      return {
        ...tag,
        id: tag.id,
        key: tag.key,
      };
    }) || [];

  const findTagsByFleetId = (fleetId: number) => {
    return fleetTagsList.filter((tag) => tag.fleet?.id === fleetId);
  };

  const value = {
    showAddTag,
    setShowAddTag,
    fleetTagsList,
    refetch,
    findTagsByFleetId,
  };
  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
}
