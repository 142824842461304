import {
  POST,
  DEFAULT_TIMEOUT,
  getAuthenticatedRequestHeaders,
  OpenAPIFetchOptions,
} from "../../services/serviceUtils";

export async function askQuestion(
  options: OpenAPIFetchOptions<typeof POST<"/customerSupport/askQuestion">>
) {
  return await POST("/customerSupport/askQuestion", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}
