import Head from "next/head";

export default function PageHead({
  title,
  shouldIncludeSubtitle = true,
}: {
  title?: string;
  shouldIncludeSubtitle?: boolean;
}) {
  let fullTitle = title;
  const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";
  const project = isInsurance ? "Insurance" : "Fleets";
  if (shouldIncludeSubtitle) {
    fullTitle = title
      ? `${title} | ServiceUp for ${project}`
      : `ServiceUp for ${project}`;
  }

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  );
}
