import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const CloseIconButton = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <IconButton
      color="secondary"
      onClick={handleClose}
      size="large"
      sx={{
        borderRadius: "50%",
        width: "36px",
        height: "36px",
        padding: 0,
        marginLeft: "16px",
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseIconButton;
