export enum Reason {
  ReportBug = "report a bug",
  SuggestImprovement = "suggest an improvement",
  AskQuestion = "ask a question",
}

export enum FromWebsite {
  ShopPortal = "Shop Portal",
  FleetPortal = "Fleet Portal",
}
