import { Box, Button, Stack, Typography } from "@mui/material";
import Dropzone from "react-dropzone";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { imageTypesForUpload } from "../lib/uploadFiles";

interface ImageDropzoneProps<T extends FieldValues> {
  imagesVariableName: Path<T>;
  images: File[];
  control: Control<T>;
}

function ImageDropzone<T extends FieldValues>({
  imagesVariableName,
  images,
  control,
}: ImageDropzoneProps<T>) {
  return (
    <Controller
      name={imagesVariableName}
      control={control}
      rules={{
        required: images.length === 0 ? "At least one image is required" : "",
      }}
      render={({ field: { onChange }, fieldState }) => (
        <Box>
          <Dropzone
            onDrop={(acceptedFiles) => {
              const newFiles = acceptedFiles.filter(
                (pf) => !images.some((img) => img.name === pf.name)
              );
              onChange([...images, ...newFiles]);
            }}
            accept={imageTypesForUpload}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                  border={3}
                  borderColor={"action.active"}
                  sx={{
                    borderStyle: "dashed",
                    backgroundColor: "background.paper",
                    zIndex: 0,
                  }}
                  width={"100%"}
                  height={144}
                  display="flex"
                  justifyContent={"center"}
                  mt={1}
                  style={{ cursor: "pointer" }}
                >
                  <Box alignSelf={"center"} zIndex={10}>
                    <Stack direction={"row"} alignItems="center" gap={1}>
                      <Typography variant="h6">
                        Drag and drop here or
                      </Typography>
                      <Button variant={"contained"} color="secondary">
                        Upload
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            )}
          </Dropzone>
          {fieldState.error && images.length === 0 && (
            <Box ml={2}>
              <Typography
                color="error"
                variant="caption"
                fontSize={14}
                fontWeight={500}
              >
                {fieldState.error.message}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    />
  );
}

export default ImageDropzone;
