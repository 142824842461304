import { Dispatch, SetStateAction } from "react";

import {
  Autocomplete,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import { GetMyFleetsQuery } from "shared/generated/graphql";

import { useFleets } from "../../providers/FleetProvider";

interface FleetOption {
  id: number;
  name: string;
}

const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

export default function AccountSwitcher({
  fleets,
  setShowModal,
  label = "Fleet",
}: {
  fleets: GetMyFleetsQuery["fleets"];
  setShowModal: Dispatch<SetStateAction<boolean>>;
  label?: string;
}) {
  const router = useRouter();
  const { allFleets } = useFlags();
  const { currentFleetId, setCurrentFleetId, loading } = useFleets();
  const isDisabled = router.asPath.startsWith("/services");

  const createOptionLabel = `+ Create ${isInsurance ? "Team" : "Fleet"}`;
  const allFleetOption: FleetOption = { id: -2, name: "All Fleets" };
  const createFleetOption: FleetOption = { id: -1, name: createOptionLabel };

  const options: FleetOption[] = [
    createFleetOption,
    ...(allFleets && !isInsurance ? [allFleetOption] : []),
    ...fleets.map((fleet) => ({
      id: fleet.id,
      name: fleet.name,
    })),
  ];

  if (loading) {
    return <CircularProgress size={20} />;
  }

  const selectedFleet =
    allFleets && currentFleetId === "all"
      ? allFleetOption
      : options.find((fleet) => fleet.id === currentFleetId);

  if (!selectedFleet) return;

  return (
    <Tooltip
      title={
        isDisabled
          ? "Switching fleets is disabled while initiating a new service."
          : null
      }
    >
      <Autocomplete
        size="small"
        fullWidth
        sx={{ maxWidth: 280 }}
        disabled={isDisabled}
        disableClearable
        options={options}
        getOptionLabel={(option) => option.name}
        value={selectedFleet}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={async (_, value) => {
          if (value.id === -1) return setShowModal(true);
          await setCurrentFleetId(
            value.id === allFleetOption.id ? "all" : value.id
          );
        }}
      />
    </Tooltip>
  );
}
