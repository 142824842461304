import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { InferType } from "yup";

import ControlledTextField from "shared/components/controlledFields/ControlledTextField";
import FormModal from "shared/components/modals/FormModal";
import { useSnackbar } from "shared/toast/SnackbarProvider";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useFleets } from "../../providers/FleetProvider";
import { createFleet } from "../../services/user.service";

interface AddFleetModalTemplateProps {
  onAddFleetSuccess: (fleet: Fleet) => void;
  onClose: () => void;
  open: boolean;
}

const validationSchema = yup.object({
  fleetName: yup
    .string()
    .required("Fleet name is required")
    .max(50, "Maximum 50 characters"),
});

type FormValues = InferType<typeof validationSchema>;

const AddFleetModal = (props: AddFleetModalTemplateProps) => {
  const { onAddFleetSuccess, open, onClose } = props;
  const { toast } = useSnackbar();

  const { currentFleets } = useFleets();
  const [creatingFleet, setCreatingFleet] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: { fleetName: "" },
    resolver: yupResolver(validationSchema),
  });

  const handleCreate = async (data: FormValues) => {
    if (!currentFleets) {
      toast.error(
        "Creating an additional fleet requires that you be in a fleet."
      );
      return;
    }
    // Company || name applies for fleets that already exist with no company name, it uses the fleet name instead as the default
    setCreatingFleet(true);
    const fleet = await createFleet(
      currentFleets[0].id,
      data.fleetName,
      currentFleets[0].company || currentFleets[0].name
    );
    setCreatingFleet(false);

    if ("message" in fleet) {
      const { message } = fleet;
      if (message) toast.error(`Could not create fleet. ${message ?? ""}`);
      return;
    }

    onAddFleetSuccess(fleet);
    reset();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <FormModal
      title={"Create Fleet"}
      subtitle={`Create additional fleets for better organization.`}
      open={open}
      handleClose={handleClose}
      onSubmit={handleSubmit(handleCreate)}
      submitButtonText="Create Fleet"
      loading={creatingFleet}
      isValid={isValid}
      maxWidth="sm"
      content={
        <Box mt={1}>
          <ControlledTextField
            name="fleetName"
            control={control}
            label="Enter Fleet Name"
            data-testid="addFleetNameField"
            error={!!errors.fleetName}
            disabled={creatingFleet}
            fullWidth
          />
        </Box>
      }
    />
  );
};

export default AddFleetModal;
