export function saveToLocalStorage(
  key: string,
  value: Parameters<typeof JSON.stringify>[0]
) {
  if (typeof window === "undefined") return;
  localStorage.setItem(key, JSON.stringify(value));
}

export function loadFromLocalStorage(
  key: string
): ReturnType<typeof JSON.parse> | null {
  if (typeof window === "undefined") return null;

  const stored = localStorage.getItem(key);
  return stored ? JSON.parse(stored) : null;
}

export function removeFromLocalStorage(key: string) {
  if (typeof window === "undefined") return;

  localStorage.removeItem(key);
}
